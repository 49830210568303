<template>
    <div class="home">
    </div>

</template>

<script>
export default {
    name: 'TheDashboard',
    components: {}
};

</script>
<style scoped>

</style>
