<template>
    <div class="">
        <vue-table-card title="Gallery" class="title-primary"
                        :fields='fields'
                        :url="listUrl" :show-search-box="false" :show-pagination="true"
                        :page-sizes="false" :per-page="5" :extra-params="false" ref="table">
            <template #buttons>
                <btn size="sm" @click="openAddModal">Upload Image</btn>
            </template>
            <template #image="{rowData}">
            <div slot="image">
                <img :src="'/upload/'+rowData.image" width="100" height="100" alt="">
            </div>
            </template>
            <template #actions="{rowData}">
                <div class="">
                    <button class="text-danger action-btn" @click="setDelete(rowData)"><i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
            <template #footer>
                <modal no-close-on-backdrop title="Upload Image" ref="uploadModal" width="50r" header-color="primary">
                    <s-form @submit="AddClicked" ref="form">
                        <div>
                            <div class="row">
                                <div class="col-lg-4">

                                    <!--                                    <label class="custom-file-upload" ref="cFileUpload">-->
                                    <!--                                        <validated-input class="c-input-upload" label="Image Upload" type="file"-->
                                    <!--                                                         placeholder="Upload Image"-->
                                    <!--                                        ></validated-input>-->
                                    <!--                                        &lt;!&ndash;                                        <div class="c-placeholder">{{ filename }}</div>&ndash;&gt;-->
                                    <!--                                    </label>-->
                                    <form-file-input class="c-input-group" v-model="model.image" label="Image Upload"></form-file-input>
                                </div>
                                <div class="col-lg-3">
                                    <!--                                    <validated-input v-model="model.name" :rules="{required: false}" label="Name"-->
                                    <!--                                                     name="Name" :loading="loading" :disabled="loading"/>-->
                                    <div class="fl-x fl-a-c h-100">

                                       <validated-select class="c-placeholder-md" label="Type" v-model="model.type"
                                                         :options="TypeOption"
                                                         placeholder="Category"
                                                     name="Type"/>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="fl-x fl-a-c h-100 mt-1">
                                        <btn size="sm" title="Upload" :loading="loading" :disabled="loading" loading-text="Saving.." type="submit"></btn>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </s-form>
                </modal>
                <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                    <p>You are about to delete the Image. Are you sure?</p>
                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete the Image.
                    </template>
                </delete-modal>
            </template>
        </vue-table-card>
    </div>
</template>

<script>

import urls from '../data/urls';

import axios from 'secure-axios';

export default {
    name: 'TheGallery',
    data () {
        return {
            loading: false,
            listUrl: urls.gallery.list,
            addUrl: urls.gallery.add,
            deleteUrl: urls.gallery.delete,
            TypeOption: [
                { value: 'interior', label: 'Interior' },
                { value: 'exterior', label: 'Exterior' },
                { value: 'decoration', label: 'Decoration' }
            ],
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'slno',
                    title: 'Id'
                },
                {
                    name: 'type',
                    title: 'Category'
                },
                {
                    name: '__slot:image',
                    dataClass: 'table-img',
                    width: '15%',
                    title: 'Image'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {
                type: '',
                image: ''

            }
        };
    },
    methods: {
        async AddClicked (action) {
            const that = this;
            that.loading = true;
            const response = await axios.form(that.addUrl, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess(response);

                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        openAddModal () {
            this.$refs.uploadModal.show();
        },
        setDelete (item) {
            console.log(item);
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });

            this.$refs.table.refreshTable();
        },
        formSuccess () {
            this.$refs.uploadModal.close();
            this.$notify('Gallery Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
