<template>
    <div>
        <vue-table-card title="Quotes" class="title-primary"
                        :fields='fields'
                        :url="listUrl" :show-search-box="false" :show-pagination="true"
                        :page-sizes="false" :per-page="5" :extra-params="false" ref="table">
            <template #actions="{rowData}">
                <div class="">
                    <button class="text-danger action-btn" @click="setDelete(rowData)"><i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
            <template #footer>
                <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                    <p>You are about to delete the quote. Are you sure?</p>
                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete the quote.
                    </template>
                </delete-modal>
            </template>
        </vue-table-card>
    </div>
</template>

<script>
import urls from '../data/urls';

export default {

    name: 'TheQuotes',
    data () {
        return {
            loading: false,
            listUrl: urls.getquote.list,
            deleteUrl: urls.getquote.delete,
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'slno',
                    title: 'Id'
                },
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'email',
                    title: 'email'
                },
                {
                    name: 'message',
                    title: 'message'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ]

        };
    },
    methods: {
        setDelete (item) {
            console.log(item);
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });

            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
